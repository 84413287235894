import { useState, useMemo, useContext } from "react";
import { useDispatch} from "react-redux";
import useLifecycles from "react-use/lib/useLifecycles";
import Link from "next/link";
import { userLogOut} from 'context/reducers/user'
import logEvent from "lib/ga/miscEvent";
import { modalSet } from "_slices/view";
import { useRouter, usePathname} from 'next/navigation'
import { Context } from 'context/context';
import headerStyles from '../Header/header.module.css';
import styles from './headerUser.module.css';
import buttons from "components/_styled/buttons.module.css";

const SiteNav = ({loggedIn}) => {
  const dispatchRedux = useDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const { dispatch, state } = useContext(Context);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { avatar_uri, avatar_url, forename, trust, social_login } = state.user;
  const avatar = useMemo(() => {
    if (social_login?.avatar_url) return social_login.avatar_url;

    if (avatar_url) return avatar_url;

    if (avatar_uri) return `${process.env.NEXT_PUBLIC_APP_MEDIA_URL}${avatar_uri}`;
    else return `${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/nav/user.svg`;
  }, [avatar_uri, avatar_url]);

  const checkForDropdownClose = (e) => {
    if (dropdownOpen && e.path.filter((el: { id: string }) => el.id && el.id === "cr-navbar-menu").length === 0)
      setDropdownOpen(false);
  };

  const handleLogOut = (e) => {
    e.preventDefault();

    logEvent({
      category: "Login",
      action: "Logged out",
    });

    userLogOut(dispatch)
    // Make sure the dropdown is closed so that when we login again the menu is not showing

    if(pathname.includes('profile')) {
      router.push('/')
    }
    setDropdownOpen(false);
  };

  useLifecycles(
    () => {
      window.addEventListener("click", checkForDropdownClose);
    },
    () => {
      window.removeEventListener("click", checkForDropdownClose);
    }
  );

  if (loggedIn)
    return (
      <div className={headerStyles.buttonContainer}>
        <Link href={'/profile'} passHref legacyBehavior prefetch={false}>
          <a className={`${headerStyles.accountButton} ${buttons.primaryButton}`}>
            My Account
          </a>
        </Link>
        <button className={buttons.primaryButton} onClick={(e) => { handleLogOut(e) }}>
          Logout
        </button>
      </div>
    );

  return (
    <span
      id="cr-user"
      className={styles.NavUser}
    >
      <button className={buttons.primaryButton} onClick={(e) => {dispatchRedux(modalSet({visible: true, type: "auth", view: "login", router: router }))}} >Sign In</button>
    </span>
  );
};

export default SiteNav;
